import {graphql, Link} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Concept and technology validation through prototyping – Step 2 of our approach"
        description="The realisation of a new software product usually depends on a concept or technique. That is why we start making a prototype."
        ogImage="prototyping-en.png"
        translatedPagePath="/nl/werkwijze/prototyping/"
        locale={locale}>
        <Link to="/en/approach/kick-off/" className="body__nav body__nav--left" title="Approach step 1. Kick-off">
            Previous step
        </Link>

        <Link
            to="/en/approach/product-realisation/"
            className="body__nav body__nav--right"
            title="Approach step 3. Product realisation">
            Next step
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Concept and <br /> technology <br /> validation
                        </h2>
                        <div className="display__number">2</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            The key to successful realisation of a new software product is generally the validation of a
                            concept or technology. That’s why we start by making a prototype.
                        </p>
                        <p>
                            In a short, intensive period our specialists get to work to cover the main product risks and
                            validate assumptions. We do this by developing a prototype focusing only on potential{' '}
                            <span className="highlight">stumbling-blocks</span> and the{' '}
                            <span className="highlight">validation</span> of assumptions.
                        </p>
                        <p>
                            We refer to the result of our efforts as a{' '}
                            <span className="highlight">proof-of-concept</span>. This is a rough design of the ultimate
                            software product. Our clients can use a proof-of-concept to convince stake&shy;holders about
                            the product idea.
                        </p>
                        <p>
                            Once we have assessed the proof-of-concept together and taken feedback from
                            stake&shy;holders into account, we establish a well&mdash;thought&mdash;out, flexible{' '}
                            <span className="highlight">development plan</span>. This plan forms the basis for realising
                            an initial version of the software product.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Iterations of 1 week</li>
                            <li className="ion-md-arrow-dropright">2 &ndash; 6 weeks</li>
                            <li className="ion-md-arrow-dropright">1 &ndash; 3 specialists</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/en/approach/product-realisation/"
                            name="Next step: product realisation"
                            title="Approach step 3. product realisation"
                        />
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
